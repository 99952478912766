<template>
  <div class="mobilePage">
    <div class="headerBox" @click="getApkInfo(true)"></div>
    <div class="topBox"></div>
    <van-tabs
      v-model="tabActive"
      title-active-color="#ffffff"
      title-inactive-color="#ffffff"
      animated
      :lazy-render="false"
      class="vantTabs"
      @change="changeTab"
    >
      <van-tab
        :title="item.label"
        v-for="(item, index) in tabs"
        :key="item.value"
      >
        <template #title>
          <div :class="`tab${index}`"></div>
        </template>
        <div class="main" @click="getApkInfo()">
          <div class="content1" v-if="item.value == 'hot'"></div>
          <div class="content2" v-if="item.value == 'darkweb'"></div>
          <div class="content3" v-if="item.value == 'cartoon'"></div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="floatingBox">
      <div class="btn1" @click="toOfficial"></div>
      <!-- <div class="btn2" @click="toPotato"></div> -->
      <div class="btn3" @click="toBusiness"></div>
    </div>
    <!-- <div class="bottomBox" @click="getApkInfo(true)">
     
    </div> -->
    <!-- 安卓提示框 -->
    <van-overlay
      :show="androidPromptShow"
      z-index="999"
      class="androidOverlay"
      @click="androidPromptShow = false"
    >
      <div>
        <div class="overlayContent">
          <div class="confirmBtnBox"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay
      :show="iosPromptShow"
      z-index="999"
      class="iosOverlay"
      @click="iosPromptShow = false"
    >
      <div>
        <div class="overlayContent">
          <div class="confirmBtnBox"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      tabActive: 0,
      tabCalss: "",
      tabs: [
        {
          label: "",
          value: "hot",
        },
        {
          label: "",
          value: "darkweb",
        },
        {
          label: "",
          value: "cartoon",
        },
      ],
      timer: null,
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    // console.log(ua);
    // console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
    this.interval();
  },
  methods: {
    interval() {
      this.timer = setInterval(() => {
        if (this.tabActive == 2) {
          this.tabActive = 0;
        } else {
          this.tabActive++;
        }
      }, 3000);
    },
    changeTab() {
      clearInterval(this.timer);
      this.interval();
    },
    // 剪贴板
    doCopy() {
      // let url = window.location.href;
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(flag) {
      let type = "android";
      if (this.os.isPhone) {
        type = "ios";
      } else {
        type = "android";
      }
      if (flag) {
        await this.showInstallTutorial(type);
      }
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toOfficial() {
      window.open("https://t.me/yaojingdm1");
    },
    toBusiness() {
      window.open("https://t.me/mu02guang");
    },
    toPotato() {
      window.open("https://ptcc.in/yaojingdm1");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  // padding: 20px 12px 0;
  // background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
  // background-size: 100% 100%;
  position: relative;
  .headerBox {
    height: 66px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/headerBox.png") no-repeat;
    background-size: 100% 100%;
  }
  .topBox {
    width: 100%;
    height: 219px;
    background: url("./../../../assets/images/mobile1/topBg.webp") no-repeat;
    background-size: 100% 100%;
  }

  .vantTabs {
    height: 100%;
    ::v-deep .van-tabs__wrap {
      height: 70px;
      width: 100%;
      position: absolute;
      top: -42px;
      left: 0;
      z-index: 10;
      background: url("../../../assets/images/mobile1/tabBg1.png") no-repeat;
      background-size: 100% 100%;
      padding: 22px 82px 0;
      // margin-top: 20px;
      .van-tab {
        // width: 70px;
        // height: 38px;
        // border-radius: 4px;
        flex: none !important;
        background: rgba(0, 0, 0, 0);
        width: 66px;
        height: 50px;
        padding: 0;

        // background: #dcccff;

        // flex-shrink: 0 !important;
        // .van-tab__text {
        //   color: #a367e3;
        //   font-size: 18px;
        // }
      }
      .van-tab--active {
        // background: linear-gradient(277deg, #8c56c2 -5.96%, #a270e3 97.44%),
        //   #dcccff;
        // .van-tab__text {
        //   color: #fff;
        // }
      }
      .van-tabs__nav {
        padding: 0 3.5px;
        background: rgba(0, 0, 0, 0) !important;
        align-items: center;
        justify-content: space-between;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tab__text {
        display: inline-block;
        width: 66px;
        height: 24px;
        overflow: visible;
      }
      .van-tab--active {
        .tab0 {
          display: inline-block;
          width: 66px;
          height: 24px;
          background: url("./../../../assets/images/mobile1/tab0.png") no-repeat;
          background-size: 100% 100%;
          transform: rotate(7deg);
        }
        .tab1 {
          display: inline-block;
          width: 66px;
          height: 24px;
          background: url("./../../../assets/images/mobile1/tab1.png") no-repeat;
          background-size: 100% 100%;
          transform: translateY(5px);
          // transform: rotate(6deg);
        }
        .tab2 {
          display: inline-block;
          width: 66px;
          height: 24px;
          background: url("./../../../assets/images/mobile1/tab2.png") no-repeat;
          background-size: 100% 100%;
          transform: rotate(-7deg);
        }
      }
    }
    ::v-deep .van-tabs__content {
      height: 100%;
      .van-tabs__track {
        height: 100%;
        .van-tab__pane-wrapper {
          height: 100%;
          .van-tab__pane {
            height: 100%;
          }
        }
      }
    }
    .main {
      height: calc(100% - 285px);
      // padding: 109px 24.5px 30px;
      // box-sizing: border-box;
      overflow-y: auto;
      .content1 {
        height: 1612px;
        width: 100%;
        background: url("./../../../assets/images/mobile1/content1.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content2 {
        height: 1612px;
        width: 100%;
        background: url("./../../../assets/images/mobile1/content2.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content3 {
        height: 781px;
        width: 100%;
        background: url("./../../../assets/images/mobile1/content3.webp")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  // .comicsTab {
  //   ::v-deep .van-tabs__wrap {
  //     background: url("../../../assets/images/mobile1/comicsTab.png") no-repeat;
  //     background-size: 100% 100%;
  //   }
  // }
  // .videoTab {
  //   ::v-deep .van-tabs__wrap {
  //     background: url("../../../assets/images/mobile1/videoTab.png") no-repeat;
  //     background-size: 100% 100%;
  //   }
  // }
  .floatingBox {
    height: 156px;
    width: 55px;
    position: fixed;
    right: 0;
    top: 251px;
    background: url("./../../../assets/images/mobile1/floatingBox.png")
      no-repeat;
    z-index: 11;
    background-size: 100% 100%;
    .btn1 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      top: 30px;
    }
    .btn2 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      top: 84px;
    }
    .btn3 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      bottom: 30px;
    }
  }
  .bottomBox {
    height: 75px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 16px 12px;
    box-sizing: border-box;
    background: url("./../../../assets/images/mobile1/bottomBg.png") no-repeat;
    background-size: 100% 100%;
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/androidTip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 33px;
        width: 128px;
        border-radius: 40px;
        margin: 0 auto;
        background: url("./../../../assets/images/mobile1/confirmBtnBox.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 33px;
        width: 128px;
        border-radius: 40px;
        margin: 0 auto;
        background: url("./../../../assets/images/mobile1/confirmBtnBox.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
